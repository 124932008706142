<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
export default {
  data() {
    return {
      deviceId: '',
      uid: '',
      token: '',
      Invite: '',
      version_code: '',
      skinId: '',
      channel: '',
      // version_code: '',
      appVersionData: '',
      flag: false, // false 未登录 true 登陆
      theme: 'theme1',
      guide_status: '',
      time: '',
      animation: ''
    }
  },
  created() {
    this.getParameter()
  },
  mounted() {},
  computed: {
    ...mapState({
      appVersion: (state) => state.parameter.appVersion
    })
  },
  watch: {},
  beforeDestroy() {
    this.setCommonParam({ number: 0, animation: 0 })
  },
  methods: {
    ...mapMutations(['setToken', 'setCommonParam']),
    getParameter() {
      const url = location.href
      console.log('Appurl', url)
      if (url.indexOf('?') >= 0) {
        var params = url.substring(url.indexOf('?') + 1, url.length)
        if (params !== null && params !== undefined && params !== '') {
          const paramArr = params.split('&')
          for (const index in paramArr) {
            const key = paramArr[index].split('=')[0]
            const val = paramArr[index].split('=')[1]
            console.log(key, val)
            // alert(paramItem)
            switch (key) {
              case 'token':
                this.token = val
                break
              case 'uid':
                this.uid = val
                break
              case 'version_code':
                this.version_code = val
                break
              case 'skinId':
                this.skinId = val
                break
              case 'guide_status':
                this.guide_status = val
                break
              case 'time':
                this.time = val
                break
              case 'animation':
                this.animation = val
                break
              default:
                break
            }
          }
        }
      }
      if (this.token !== '') {
        /** 登陆一定会有token 没有的话 是除了motion和integarl的其他页面 走的是缓存 */
        // 这里才是更改
        console.log('this.token', this.token)
        this.setToken(this.token)
        this.setCommonParam({
          uid: this.uid,
          version_code: this.version_code,
          skinId: this.skinId,
          guide_status: this.guide_status,
          time: this.time,
          animation: this.animation
        })
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
