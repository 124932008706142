<template>
  <div class="home">
    <div class="explore">
      <p>我的探索</p>
      <div class="money">
        <p>{{ current }}</p>
        <p>{{ currentMoney }}</p>
        <div class="withdrawDepositCover">
          <div
            v-if="
              commonParam.version_code &&
              Number(commonParam.version_code) >= 10004
            "
            class="withdrawDeposit"
            @click="webCabin"
          >
            飞船信息
          </div>
          <div class="withdrawDeposit" @click="withdrawDeposit">提现</div>
        </div>
      </div>
      <div class="wenzi">
        <p v-if="unit === 1">可兑换里程(万公里)</p>
        <p v-else-if="unit === 2">可兑换里程(亿公里)</p>
        <p v-else>可兑换里程(光年)</p>
        <p v-if="unitMoney === 1">现金余额(元）</p>
        <p v-else-if="unitMoney === 2">现金余额(万元）</p>
        <p v-else>现金余额(百元）</p>
      </div>
    </div>
    <van-pull-refresh v-model="loading" @refresh="onRefresh">
      <div v-if="timeSure">

        <div v-if="listDewUser[0] && listDewUser[0].status !== 3" class="task">
          新手福利
        </div>
        <div
          v-if="listDewUser[0] && listDewUser[0].status !== 3"
          class="newUserTask"
        >
        <!-- 动画 -->
        <div class="pop_1" v-if="pop_1&&all_pop">
          <img src="../../img/pop_1.gif" alt="" />
        </div>
          <div
            class="newList"
            v-for="(item, index) in listDewUser"
            :key="index"
          >
            <div class="taskList">
              <img :src="item.icon" alt="" />
              <div class="content content2">
                <!-- <p>{{ item.desc }}</p> -->
                <div class="new-img">
                  <img src="../../img/jsq.png" alt="" />
                  <div>+150亿里程</div>
                </div>
                <p>{{ item.name }}({{ item.done_num }}/{{ item.total_num }})</p>
                <!-- <span>每日签到18000万公里+加速燃料10%</span> -->
              </div>
              <!-- <div class="btn-task" v-if="item.status === 0">去完成</div> -->
              <!-- 倒计时 -->
              <div
                class="btn-task btn-task-new"
                v-if="item.status === 1||item.status === 0"
                @click="daojishi_19 === '去完成' ? doTask(item, index) : ''"
              >
                {{ daojishi_19 }}
              </div>
              <div class="btn-task-success" v-else-if="item.status === 3">
                已完成
              </div>
              <div
                class="btn-task-success"
                v-else-if="item.status === 2"
                @click="doTask(item, index)"
              >
                去领取
              </div>
              <div class="btn-task-success" v-else>已过时</div>
            </div>
          </div>
        </div>
      </div>

      <div class="task">每日任务</div>
      <!-- <div class="new1">每日飞船探索多多，奖励多多</div> -->
      <!-- 下拉刷新 -->
      <div class="allTaskList">
        <div v-for="(item, index) in listDaily" :key="index">
          <div class="taskList" v-if="item.tag !== 'download'">
            <!-- 动画 -->
            <div class="pop_2" v-if="pop_2 && item.tag == 'time_task_speed'&&all_pop">
              <img src="../../img/pop_1.gif" alt="" />
            </div>
            <div class="pop_2" v-if="pop_3 && item.tag == 'daily_lottery'&&all_pop">
              <img src="../../img/pop_1.gif" alt="" />
            </div>
            <div class="pop_2" v-if="pop_4 && item.tag == 'daily_benefits'&&all_pop">
              <img src="../../img/pop_1.gif" alt="" />
            </div>

            <img :src="item.icon" alt="" />
            <div class="content">
              <span>{{ item.desc }}</span>
              <p
                v-if="item.tag === 'time_task_speed' || item.tag === 'download'"
              >
                {{ item.name }} ({{ item.done_num }} / {{ item.total_num }})
              </p>
              <p v-else>{{ item.name }}</p>
            </div>
            <div
              class="btn-task"
              v-if="(item.status === 0||item.status === 1) && item.tag === 'time_task_speed'"
              @click="daojishi_4 === '去完成' ? doTask(item, index) : ''"
            >
              {{ daojishi_4 }}
            </div>
            <div
              :class="
                daojishi_5 === '点击加倍' ? 'btn-task' : 'btn-task-success'
              "
              v-else-if="item.tag === 'time_task_1'"
              @click="daojishi_5 === '点击加倍' ? doTask(item, index) : ''"
            >
              {{ daojishi_5 }}
            </div>
            <div
              class="btn-task"
              v-else-if="(item.status === 0||item.status === 1) && item.tag !== 'time_task_speed'"
              @click="doTask(item, index)"
            >
              去完成
            </div>
            <div
              class="btn-task-success"
              v-else-if="item.status === 3"
              @click="doTask(item, index)"
            >
              已完成
            </div>
            <div
              class="btn-task-success"
              v-else-if="item.status === 2"
              @click="doTask(item, index)"
            >
              去领取
            </div>
            <div class="btn-task-success" v-else>已过时</div>
          </div>
        </div>
      </div>
    </van-pull-refresh>
    <!-- 引导页 不要了 -->
    <!-- <van-popup v-model="guide_status" :close-on-click-overlay="false">
      <div class="popup">
        <img src="../../img/zy-btn.png" alt="" @click="withdrawDeposit" />
      </div>
    </van-popup> -->
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'Home',
  data() {
    return {
      current: '',
      currentMoney: '',
      loading: false,
      list: [],
      listDaily: [],
      listDewUser: [],
      unit: 1,
      unitMoney: 1,
      dateTime: 0,
      guide_status: false,
      daojishi_19: '',
      btnInterval_19: '',

      btnInterval_4: '',
      daojishi_4: '',

      daojishi_5: '',
      btnInterval_5: '',
      allNumber: '0',
      timeSure: false,
      // 新手视频
      newTime: '50',
      newTimeNum: '1',
      // 每日视频
      dailyVideo: '50',
      dailyVideoNum: '1',
      pop_1: false,
      pop_2: false,
      pop_3: false,
      pop_4: false,
      all_pop: true
    }
  },
  created() {
    this.getUser()
    this.getUserMoney()
    this.getList()
    this.getTimeNumber()
    if (this.commonParam.guide_status === '0') {
      this.guide_status = true
    }
    // if (this.commonParam.time < '1653638400000') {
    //   this.timeSure = false
    // }
    if (this.commonParam.animation === '1') {
      this.pop_1 = true
      this.setCommonParam({
        animation: 0
      })
    } else if (this.commonParam.animation === '2') {
      this.pop_2 = true
      this.setCommonParam({
        animation: 0
      })
    } else if (this.commonParam.animation === '3') {
      this.pop_3 = true
      this.setCommonParam({
        animation: 0
      })
    } else if (this.commonParam.animation === '4') {
      this.pop_4 = true
      this.setCommonParam({
        animation: 0
      })
    }
  },
  mounted() {
    window.adsuccess = this.adsuccess
    // this.adsuccess()
  },
  computed: {
    ...mapState(['commonParam'])
  },
  methods: {
    ...mapMutations(['setToken', 'setCommonParam']),
    // 自定义时间次数
    async getTimeNumber() {
      const result = await this.$api.list.getNewTimeNumber()
      if (result.code === 0) {
        this.newTime = result.data.newTime
        this.newTimeNum = result.data.newTimeNum
        this.dailyVideo = result.data.dailyVideo
        this.dailyVideoNum = result.data.dailyVideoNum
      }
    },
    // 成功的回调
    adsuccess(type) {
      this.getUser()
      this.getUserMoney()
      this.getList()
      if (type && type !== 0 && type !== 'undefined') {
        // 看视频的情况，看完广告需要此处上传时间戳
        this.putMessage(type)
      }
    },
    // 用户里程
    async getUser() {
      const result = await this.$api.list.getUserInfo({
        app_name: 'com.taihe.metaverse.discover',
        // user_id: this.commonParam.uid,
        score_type: 'default'
      })
      if (result.code === 0) {
        this.current = result.data.current
        // this.current = 1000000000
        if (this.current < 10000) {
          this.unit = 1
        } else if (this.current <= 100000000) {
          this.current = (this.current / 10000).toFixed(3)
          this.unit = 2
        } else {
          this.current = (this.current / 964000000).toFixed(2)
          this.unit = 3
        }
      }
    },
    // 用户金额
    async getUserMoney() {
      const result = await this.$api.list.getUserInfo({
        app_name: 'com.taihe.metaverse.discover',
        // user_id: this.commonParam.uid,
        score_type: 'cash'
      })
      if (result.code === 0) {
        if (result.data.current === 0) {
          this.currentMoney = 0
          return
        }
        this.currentMoney = Number(result.data.current) / Number(result.data.exchange_rate)
        // this.currentMoney = 10000
        if (this.currentMoney >= 10000) {
          this.unitMoney = 2
          this.currentMoney = (this.currentMoney / 10000).toFixed(2)
        } else if (this.currentMoney >= 100) {
          this.unitMoney = 3
          this.currentMoney = (this.currentMoney / 100).toFixed(2)
        }
      }
    },
    async getList() {
      const result = await this.$api.list.getTaskList({
        package_name: 'com.taihe.metaverse.discover'
      })
      const result2 = await this.$api.list.getNewNumber({
        types: 'ad_sign_199',
        flag: 1
      })
      if (result2.code === 0) {
        this.allNumber = result2.data
      }

      // 每日任务
      if (result.code === 0) {
        this.list = result.data.task
        this.list[0].total_num = 10
        this.list[0].done_num = Number(this.allNumber)
        this.listDaily = this.list.filter(function(item) {
          return item.group_name === 'daily'
        })
        if (
          this.listDaily[0].tag === 'daily_check_in,10' &&
          this.listDaily[0].status === 3
        ) {
          this.listDaily.shift()
        }
        const myDate = new Date()
        this.dateTime = myDate.getHours()
        // this.dateTime = 20
        // console.log(this.dateTime)
        // if (this.dateTime > 11) {
        //   this.listDaily.forEach((value) => {
        //     if (value.tag === 'time_task_1') {
        //       value.status = 3
        //     }
        //   })
        // }
        // if (this.dateTime > 19) {
        //   this.listDaily.forEach((value) => {
        //     if (value.tag === 'time_task_2') {
        //       value.status = 3
        //     }
        //   })
        // }
        this.listDewUser = this.list.filter(function(item) {
          return item.group_name === 'new_user'
        })
      }
      // if (this.commonParam.version_code === '10000') {
      //   this.listDaily.splice(this.listDaily.length - 1)
      // }
      // console.log(this.commonParam.version_code, 'version_code')
      // console.log(this.commonParam.version_code === '10000', 'version_code')

      // 新手看视频
      if (
        this.listDewUser[0] &&
        this.listDewUser[0].done_num > 0 &&
        this.listDewUser[0].done_num < 10
      ) {
        const time_19 = await this.getMessage(19, 1)
        if (time_19.data && time_19.data.length) {
          clearInterval(this.btnInterval_19)
          this.btnInterval_19 = setInterval(() => {
            this.daojishi_19 = this.showtime(
              this.btnInterval_19,
              time_19.data,
              (this.listDewUser[0].done_num - this.newTimeNum) * this.newTime // 倒计时控制
            )
          }, 1000)
        } else {
          this.daojishi_19 = '去完成'
        }
      } else {
        this.daojishi_19 = '去完成'
      }
      const time_task_speed = this.listDaily.filter(function(item) {
        return item.tag === 'time_task_speed'
      })
      // 每日 看视频
      if (
        time_task_speed[0] &&
        time_task_speed[0].done_num > 0 &&
        time_task_speed[0].done_num < 10
      ) {
        const time_4 = await this.getMessage(4, 0)
        if (time_4.data && time_4.data.length) {
          clearInterval(this.btnInterval_4)
          this.btnInterval_4 = setInterval(() => {
            this.daojishi_4 = this.showtime(
              this.btnInterval_4,
              time_4.data,
              (time_task_speed[0].done_num - this.dailyVideoNum) * this.dailyVideo // 倒计时控制
            )
          }, 1000)
        } else {
          this.daojishi_4 = '去完成'
        }
      } else {
        this.daojishi_4 = '去完成'
      }
      // 加倍
      const time_5 = await this.getMessage(5, 0) // 加倍
      if (time_5.data && time_5.data.length) {
        clearInterval(this.btnInterval_5)
        this.btnInterval_5 = setInterval(() => {
          this.daojishi_5 = this.showtime(
            this.btnInterval_5,
            time_5.data,
            // 1660636800000,
            7200
          )
        }, 1000)
        console.log(time_5, 'time_5')
        console.log(this.btnInterval_5, 'time_5')
      } else {
        this.daojishi_5 = '点击加倍'
      }
    },
    async onRefresh() {
      // const result = await this.$api.list.getTaskList()
      // if (result.code === 0) {
      //   this.list = result.data.tasks
      //   this.listDaily = this.list.filter(function(item) {
      //     return item.group_name === 'daily'
      //   })
      //   const myDate = new Date()
      //   this.dateTime = myDate.getHours()
      //   // this.dateTime = 10
      //   // console.log(this.dateTime)
      //   // if (this.dateTime > 11) {
      //   //   this.listDaily.forEach((value) => {
      //   //     if (value.tag === 'time_task_1') {
      //   //       value.status = 3
      //   //     }
      //   //   })
      //   // }
      //   // if (this.dateTime > 19) {
      //   //   this.listDaily.forEach((value) => {
      //   //     if (value.tag === 'time_task_2') {
      //   //       value.status = 3
      //   //     }
      //   //   })
      //   // }
      //   this.listDewUser = this.list.filter(function(item) {
      //     return item.group_name === 'new_user'
      //   })
      //   //  this.getList()
      //   this.loading = false
      //   this.$toast('刷新成功')
      // }
      // if (this.commonParam.version_code === '10000') {
      //   this.listDaily.splice(this.listDaily.length - 1)
      // }
      await this.getList()
      this.loading = false
      this.$toast('刷新成功')
    },
    withdrawDeposit() {
      // window.android.withdrawDeposit && window.android.withdrawDeposit()
      this.$router.push({ path: '/mileage' })
      // 埋点
      window.android.webEventStep &&
        window.android.webEventStep('h5_booster_with_know')
    },
    webCabin() {
      window.android.webCabin && window.android.webCabin()
    },
    doTask(item, index) {
      // console.log(index)
      // console.log(typeof (index))
      // console.log(typeof (item.name))
      // console.log(typeof (item.award_num))
      // console.log(typeof (item.event_name))
      // console.log(item.event_name)
      // console.log(typeof (item.done_num))
      // console.log(typeof (item.id))
      // if (item.tag === 'time_task_1') {
      //   if (this.dateTime < 11) {
      //     this.$toast('时间没到，请耐心等待........')
      //     return
      //   }
      // } else if (item.tag === 'time_task_2') {
      //   if (this.dateTime < 17) {
      //     this.$toast('时间没到，请耐心等待........')
      //     return
      //   }
      // }
      this.all_pop = false
      if (this.commonParam.version_code === '10000') {
        window.android.doTask &&
          window.android.doTask(
            index,
            item.name,
            item.award_num,
            item.event_name,
            item.done_num,
            item.id,
            item.tag,
            item.status
          )
      } else {
        window.android.doTask &&
          window.android.doTask(
            index,
            item.name,
            item.award_num,
            item.event_name,
            item.done_num,
            item.id,
            item.tag,
            item.status,
            item.total_num
          )
      }

      // this.getList()
    },
    async putMessage(type) {
      const date = new Date().getTime().toString()
      await this.$api.list.putMessage({
        types: 'ad_sign_' + type,
        value: date,
        flag: type === 19 ? 1 : 0
      })
    },
    async getMessage(type, flag) {
      const result = await this.$api.list.getMessage({
        types: 'ad_sign_' + type,
        flag: flag
      })
      return result
    },
    showtime(el, data, addSeconds) {
      if (!data) {
        clearInterval(el)
        return '去完成'
      }
      var nowtime = new Date() // 获取当前时间
      // var endtime = new Date('2022/5/16 11:16:00') // 定义倒计时结束时间 需大于当前时间
      var endtime = new Date((Math.floor(data / 1000) + addSeconds) * 1000)

      var lefttime = endtime.getTime() - nowtime.getTime() // 距离结束时间的毫秒数
      var hourTime = Math.floor(lefttime / (1000 * 60) / 60)
      var leftm = Math.floor((lefttime / (1000 * 60)) % 60) // 计算分钟数
      leftm = leftm < 10 ? '0' + leftm : leftm
      var lefts = Math.floor((lefttime / 1000) % 60) // 计算秒数
      lefts = lefts < 10 ? '0' + lefts : lefts
      if (lefttime < 0 && addSeconds === 7200) {
        clearInterval(el)
        return '已完成'
      } else if (lefttime < 0) {
        clearInterval(el)
        return '去完成'
      }
      if (hourTime > 0) {
        return hourTime + ':' + leftm + ':' + lefts // 返回倒计时的字符串
      }
      return leftm + ':' + lefts // 返回倒计时的字符串
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  min-height: 100vh;
  background: url(../../img/bg.png) center no-repeat;
  background-size: cover;
  padding: 30px 10px 0 10px;
  box-sizing: border-box;
  .explore {
    width: 373px;
    height: 173px;
    background: url(../../img/explore.png) center no-repeat;
    background-size: 100% 100%;
    padding: 29px 0 0 26px;
    margin-left: 10px;
    p {
      width: 100%;
      height: 22px;
      line-height: 22px;
      font-size: 16px;
      color: #fff;
      font-weight: 500;
      text-align: start;
    }
    .money {
      width: 100%;
      height: 45px;
      line-height: 45px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 15px 0 0 0px;
      p {
        width: 150px;
        color: #fedb2e;
        font-size: 32px;
        height: 32px;
        line-height: 32px;
      }
      p:nth-child(2) {
        width: 78px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        height: 32px;
        // white-space: nowrap;s
        margin-right: 20px;
      }
      .withdrawDepositCover {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      .withdrawDeposit {
        width: 81px;
        height: 38px;
        background: url(../../img/btn-tx.png) center no-repeat;
        background-size: 100% 100%;
        font-size: 13px;
        color: #ba5100;
        line-height: 38px;
        position: relative;
        z-index: 250000;
      }
    }
    .wenzi {
      display: flex;
      justify-content: flex-start;
      p {
        width: 147px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #fff;
        margin: 1px 0 0 2px;
      }
    }
  }
  .task {
    // width: 100%;
    height: 22px;
    line-height: 22px;
    font-size: 16px;
    font-weight: 500;
    text-align: start;
    margin: 18px 0 8px 15px;
  }
  .newList {
    .taskList {
      p {
        width: 177px !important;
        text-align: center !important;
      }
    }
  }
  // .allTaskList {
  //   overflow: auto;
  //   height: 475px;
  // }
  .taskList {
    width: 395px;
    height: 104px;
    background: url(../../img/bg-taskList.png) center no-repeat;
    background-size: 100% 100%;
    padding: 25px 12px 11px 26px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    margin-top: -11px;
    img {
      width: 54px;
      height: 54px;
      margin-right: 9px;
    }
    .content {
      margin-top: 0px;
      p {
        width: 100%;
        height: 22px;
        line-height: 22px;
        font-size: 16px;
        font-weight: 500;
        text-align: start;
        margin-top: 16px;
      }
      span {
        display: block;
        // width: 180px;
        // width: 100%;
        height: 17px;
        font-size: 14px;
        font-weight: 400;
        color: #fedb2e;
        line-height: 17px;
        text-align: start;
      }
    }
    .content2 {
      position: relative;
      p {
        width: 180px !important;
        color: #e60012;
        text-align: start !important;
        font-size: 14px;
        margin-top: 5px;
      }
      .new-img {
        position: absolute;
        left: 205px;
        top: 0;
        display: flex;
        align-items: center;
        width: 250px;
        img {
          width: 18px;
          height: 12px;
          margin-right: 0;
        }
        div {
          color: #fedb2e;
          font-size: 12px;
          text-align: start;
          transform: scale(0.8);
          -webkit-transform: scale(0.8);
          margin-left: -7px;
        }
      }
    }
    .btn-task {
      width: 81px;
      height: 38px;
      background: url(../../img/btn-task1.png) center no-repeat;
      background-size: 100% 100%;
      line-height: 38px;
      color: #ba5100;
      font-size: 13px;
      position: absolute;
      right: 24px;
      top: 48px;
    }
    .btn-task-new {
      top: 48px;
    }
    .btn-task-success {
      width: 81px;
      height: 38px;
      background: url(../../img/btn-task2.png) center no-repeat;
      background-size: 100% 100%;
      line-height: 38px;
      color: #fff;
      font-size: 13px;
      position: absolute;
      right: 24px;
      top: 48px;
    }
  }
  .new1 {
    width: 397px;
    height: 106px;
    background: url(../../img/bg-new1.png) center no-repeat;
    background-size: 100% 100%;
    font-size: 18px;
    font-weight: 500;
    line-height: 106px;
    color: #fedb2e;
  }
}
// 弹窗
.popup {
  width: 288px;
  height: 137px;
  background: url(../../img/zy-1.png) center no-repeat;
  margin-top: 500px;
  position: relative;
  padding-bottom: 200px;
  overflow: inherit;
  img {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%);
  }
}
.pop_1 {
  position: absolute;
  left: 315px;
  top: 70px;
  z-index: 50;
  pointer-events: none;
  opacity: 0.75;
  width: 60px;
  height: 60px;
  img{
    width: 100%;
    height: 100%;
  }
}
.pop_2{
  position: absolute;
  left: 320px;
  top: 50px;
  z-index: 50;
  pointer-events: none;
  opacity: 0.75;
}
</style>
